import React from "react"

// Libraries
import styled from "styled-components"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

// Utils
import { colors } from "utils/variables/"
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"

const StyledHero = styled.section`
  margin-bottom: 64px;

  .hero__background {
    width: 100%;
    height: 268px;

    ${breakpoint.medium`
      height: 530px;
    `}
  }

  .hero__image {
    max-width: 1024px;
    aspect-ratio: 16/9;
    display: flex;
    justify-content: center;
    margin: -36% auto 32px auto;
    background-size: cover;
    box-sizing: border-box;

    ${breakpoint.medium`
      margin-top: -315px;
      margin-bottom: 72px;
    `}
  }

  .hero__details {
    max-width: 736px;
    margin: 0 auto;
    padding-bottom: 40px;
    border-bottom: 2px solid ${colors.cloudy};

    .categories {
      display: inline-block;
      margin-right: 16px;
      margin-bottom: 16px;

      span {
        margin-right: 8px;

        &:last-child {
          margin: 0;
        }
      }
    }

    .hero__author {
      display: flex;
      align-items: center;
      margin-top: 24px;

      .author-image {
        width: 56px;
        height: 56px;
        margin-right: 8px;
      }
    }
  }
`

const Hero = ({ data }) => {
  const { title, author, featuredImage, categories, publishing } = data
  const image = getImage(featuredImage)

  return (
    <StyledHero>
      <div className="hero__background bg--midnight"></div>
      <Container>
        <div className="hero__image">
          <GatsbyImage image={image} alt={title} />
        </div>

        <div className="hero__details">
          {categories &&
            categories.map(category => (
              <h4 className="categories color--indiblue font-weight--700">
                <span>{category.toUpperCase()}</span>
              </h4>
            ))}

          <h2>{title}</h2>

          {author && (
            <div className="hero__author">
              <GatsbyImage image={getImage(author.memoji)} alt=" " />
              <div className="author-details">
                <p className="paragraph--detail">{author.name}</p>
                <p className="paragraph--detail color--stone">{publishing}</p>
              </div>
            </div>
          )}
        </div>
      </Container>
    </StyledHero>
  )
}

export default Hero
