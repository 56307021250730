import React, { useState } from "react"

// Libraries
import PropTypes from "prop-types"
import styled from "styled-components"
import { renderRichText } from "gatsby-source-contentful/rich-text"

// Utils
import breakpoint from "utils/breakpoints/"
import { colors } from "utils/variables/"

// Icons
import IconClipboard from "assets/icons/icon-clipboard.inline.svg"
import IconCheck from "assets/icons/icon-check.inline.svg"
import DialogCaret from "assets/icons/icon-dialog-caret-rounded.svg"

const StyledCodeSnippet = styled.div`
  position: relative;
  padding: 24px;
  margin: 2.5em 0;

  .snippet__language {
    height: 24px;
    position: absolute;
    top: -12px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2px 8px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.4em;
  }

  p {
    margin: 0 !important;
  }

  pre {
    white-space: break-spaces;
  }

  .snippet__copy-to-clipboard {
    width: 32px;
    height: 32px;
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.3s ease;

    &:hover {
      .tooltip {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .icon-clipboard {
      width: auto;
      height: 16px;
    }

    .tooltip {
      display: none;

      ${breakpoint.medium`
        position: absolute;
        bottom: 150%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        padding: 4px 12px;
        background-color: ${colors.midnight};
        color: ${colors.white};
        font-size: 16px;
        white-space: nowrap;
        text-align: center;
        border-radius: 8px;
        transform: translateY(16px);
        opacity: 0;
        transition: all 0.3s;
        box-sizing: border-box;


        &::after {
          content: url(${DialogCaret});
          width: 12px;
          height: 8px;
          position: absolute;
          right: 0;
          bottom: 2px;
          left: 0;
          margin: 0 auto;
        }
      `}
    }
  }
`

const CodeSnippet = props => {
  const { language, code } = props

  // State
  const [isCopied, setIsCopied] = useState(false)

  const copyToClipboard = () => {
    let rawCode = JSON.parse(code.raw)
    rawCode = rawCode.content[0].content[0].value
    navigator.clipboard.writeText(rawCode)
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 2000)
  }

  return (
    <StyledCodeSnippet className="bg--cream">
      <button
        type="button"
        className="snippet__copy-to-clipboard bg--indiblue bg-hover--midnight"
        onClick={copyToClipboard}
      >
        {!isCopied ? (
          <IconClipboard className="icon-clipboard svg--stroke-white" />
        ) : (
          <IconCheck className="svg--stroke-white" />
        )}

        <span className="tooltip">
          {!isCopied ? "Click to copy" : "Copied"}
        </span>
      </button>
      {language && (
        <span className="snippet__language bg--supernova color--midnight">
          {language}
        </span>
      )}

      {code && <pre>{renderRichText(code)}</pre>}
    </StyledCodeSnippet>
  )
}

CodeSnippet.propTypes = {
  language: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
}

export default CodeSnippet
