import React, { useState, useEffect } from "react"

// Libraries
import styled from "styled-components"

// Utils
import breakpoint from "utils/breakpoints/"

const StyledProgressBar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(${props => (props.show ? "0" : "-100%")});
  transition: all 0.3s ease;
  z-index: 9998;

  .progress-bar {
    width: 0;
    height: 2px;

    ${breakpoint.medium`
      height: 4px;
    `}
  }
`

const ProgressBar = () => {
  const [show, setShow] = useState(true)

  useEffect(() => {
    const handleScroll = () => {
      const contentEnd = document.getElementById("contentEnd")
      const progressBar = document.getElementById("progressBar")

      if (contentEnd && progressBar) {
        const scrollPosition = window.scrollY
        const distanceToTop = contentEnd.offsetTop

        const progress = (scrollPosition * 100) / distanceToTop

        progressBar.style.width = progress + "%"

        if (progress >= 100) {
          setShow(false)
        } else {
          setShow(true)
        }
      }
    }

    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => window.removeEventListener("scroll", handleScroll)
  }, [])

  return (
    <StyledProgressBar show={show}>
      <div id="progressBar" className="progress-bar bg--indiblue"></div>
    </StyledProgressBar>
  )
}

export default ProgressBar
