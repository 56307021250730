import React, { useEffect, useState } from "react"

// Libraries
import { graphql, useStaticQuery } from "gatsby"

// Utils
import { colors } from "utils/variables/"

// Layout
import Layout from "layouts/layout-primary.js"

// Context
import ContextConsumer from "components/context"

// Components
import SEO from "components/seo/"

// Sections
import ReadingProgress from "sections/blog-post/reading-progress"
import Hero from "sections/blog-post/hero"
import Content from "sections/blog-post/content"
import RelatedPosts from "sections/blog-post/related-posts"
import NewsletterBlog from "components/newsletter-blog"

const BlogPost = props => {
  // Props
  const {
    data: {
      contentfulBlogPost: data,
      spanishPosts: { nodes: spanishPosts },
      englishPosts: { nodes: englishPosts },
    },
  } = props

  const {
    node_locale,
    title,
    featuredImage: {
      file: { url: imageUrl },
    },
  } = data
  const { relatedPosts } = data

  // State
  const [spanishLink, setSpanishLink] = useState(null)
  const [englishLink, setEnglishLink] = useState(null)

  useEffect(() => {
    if (node_locale === "en") {
      setSpanishLink(
        `/blog/${
          spanishPosts[englishPosts.findIndex(post => post.title === title)]
            .slug
        }/`
      )
    } else {
      setEnglishLink(
        `/blog/${
          englishPosts[spanishPosts.findIndex(post => post.title === title)]
            .slug
        }/`
      )
    }
  }, [])

  return (
    <Layout
      menuColor={colors.indiblue}
      englishLink={englishLink}
      spanishLink={spanishLink}
    >
      <ContextConsumer>
        {({ data, set }) => {
          if (data.useYellowFooter) {
            set({ useYellowFooter: false })
          }
          if (data.darkMenu) {
            set({ darkMenu: false })
          }
          if (!data.showFooter) {
            set({ showFooter: true })
          }
          if (data.simplifiedFooter) {
            set({ simplifiedFooter: false })
          }
        }}
      </ContextConsumer>
      <SEO
        title={title + " - Indicius Design Agency"}
        description="A passionate and experienced design thinker who can tackle complex tasks and transform them into intuitive, accessible, and easy-to-use designs."
        image={imageUrl}
      />
      <ReadingProgress />
      <Hero data={data} />
      <Content data={data} />
      <NewsletterBlog />
      <RelatedPosts data={relatedPosts} />
    </Layout>
  )
}

export const query = graphql`
  query($id: String, $language: String!) {
    contentfulBlogPost(id: { eq: $id }) {
      node_locale
      featuredImage {
        gatsbyImageData(width: 1220, height: 500, quality: 90)
        file {
          url
        }
      }
      title
      author {
        memoji {
          gatsbyImageData(width: 56, height: 56)
        }
        name
      }
      publishing(formatString: "MMM d, y")
      categories
      content {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            gatsbyImageData(width: 736, quality: 100)
          }

          ... on ContentfulCodeSnippet {
            contentful_id
            internal {
              type
            }
            language
            code {
              raw
            }
          }
        }
      }
      excerpt {
        excerpt
      }
      slug
      relatedPosts {
        title
        categories
        slug
        featuredImage {
          gatsbyImageData(width: 334, height: 180)
        }
      }
    }

    englishPosts: allContentfulBlogPost(filter: { node_locale: { eq: "en" } }) {
      nodes {
        title
        slug
      }
    }

    spanishPosts: allContentfulBlogPost(filter: { node_locale: { eq: "es" } }) {
      nodes {
        title
        slug
      }
    }

    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

export default BlogPost
