import React from "react"

// Libraries
import styled from "styled-components"
import { BLOCKS } from "@contentful/rich-text-types"
import { renderRichText } from "gatsby-source-contentful/rich-text"

// Utils
import { colors } from "utils/variables/"
import breakpoint from "utils/breakpoints/"
import { getSlug } from "utils/functions/"

// Components
import Container from "components/container/"
import CodeSnippet from "components/code-snippet/"

// Icons
import LinkedIn from "assets/icons/icon-linkedin.inline.svg"
import Twitter from "assets/icons/icon-twitter.inline.svg"
import { GatsbyImage } from "gatsby-plugin-image"

const StyledContent = styled.section`
  padding-bottom: 60px;
  font-size: 18px;
  line-height: 26px;

  ${breakpoint.medium`
    padding-bottom: 120px;
  `}

  ${Container} {
    max-width: 736px;
  }

  hr {
    max-width: 256px;
    width: 256px;
    height: 1px;
    margin: 24px 0;
    background-color: ${colors.cloudy};
    border: 0;
  }

  h2,
  h3,
  h4 {
    margin-top: 64px;
    color: ${colors.midnight};

    & + h2,
    & + h3,
    & + h4 {
      margin-top: 32px;
    }
  }

  .image {
    margin: 32px 0;
  }

  p {
    margin: 1rem 0;
  }

  figure {
    margin-top: 64px;

    figcaption {
      display: none;
    }
  }

  a {
    width: auto;
    position: relative;
    display: inline-flex;
    color: ${colors.indiblue};
    transition: all 0.3s;

    &:hover {
      color: ${colors.midnight};

      &::before {
        width: calc(100% + 16px);
      }
    }

    &::before {
      content: "";
      width: 0;
      height: 60%;
      position: absolute;
      bottom: 4px;
      left: -8px;
      background-color: ${colors.supernova};
      border-radius: 8px;
      transform: rotate(-1deg);
      transition: all 0.3s;
      z-index: -1;
    }
  }

  ul {
    li {
      margin-bottom: 8px;

      &::before {
        content: "";
        width: 4px;
        height: 4px;
        position: relative;
        top: -4px;
        display: inline-block;
        margin-right: 8px;
        background-color: ${colors.indiblue};
        border-radius: 50%;
      }

      &:last-child {
        margin-bottom: 0;
      }

      p {
        display: inline;
      }
    }
  }

  blockquote {
    position: relative;
    padding-left: 60px;
    margin: 2rem 0;

    ${breakpoint.medium`
      padding-left: 104px;
      margin: 3rem 0;
    `}

    &::before {
      content: "“";
      position: absolute;
      top: 12px;
      left: 0;
      display: flex;
      margin-right: 24px;
      color: ${colors.indiblue};
      font-family: "DM Sans";
      font-size: 110px;
      line-height: 80px;
      font-weight: 500;
      font-feature-settings: "salt" on;

      ${breakpoint.medium`
        top: 54px;
        margin-right: 32px;
        font-size: 200px;
      `}
    }

    em {
      color: ${colors.stone};
    }
  }

  iframe {
    margin: 2rem 0;
  }

  code {
    font-size: 14px;
    padding: 4px;
    background-color: ${colors.cream};
    border-radius: 2px;
  }

  .content__share {
    h4 {
      margin: 0 0 24px 0;
      text-transform: uppercase;
    }

    a {
      display: inline-flex;
      margin-right: 16px;

      &::before {
        content: none;
      }

      &:last-child {
        margin: 0;
      }
    }

    svg {
      width: 24px;
      height: auto;

      * {
        transition: all 0.3s ease;
      }
    }
  }
`

const Content = ({ data }) => {
  const { title, content } = data

  const renderOptions = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        const {
          data: {
            target: { gatsbyImageData },
          },
        } = node

        return <GatsbyImage image={gatsbyImageData} className="image" alt="" />
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        const {
          data: { target: data },
        } = node

        const {
          internal: { type },
        } = data

        if (type === "ContentfulCodeSnippet") {
          const { language, code } = data

          return <CodeSnippet code={code} language={language} />
        }
      },
    },
  }

  return (
    <StyledContent>
      <Container>
        {renderRichText(content, renderOptions)}

        <div id="contentEnd" />

        <div className="content__share">
          <h4 className="color--midnight font-weight--700">Share this story</h4>
          <a
            href={
              "https://www.linkedin.com/sharing/share-offsite/?url=https://indicius.com/blog/" +
              getSlug(title)
            }
            className="svg--fill-stone svg-hover--fill-midnight"
            target="_blank"
            rel="noopener noreferrer"
          >
            <LinkedIn />
          </a>

          <a
            href={
              "https://twitter.com/share?url=https://indicius.com/blog/" +
              getSlug(title)
            }
            className="svg--fill-stone svg-hover--fill-midnight"
            target="_blank"
            rel="noopener noreferrer"
          >
            <Twitter />
          </a>
        </div>
      </Container>
    </StyledContent>
  )
}

export default Content
