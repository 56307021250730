import React from "react"

// Libraries
import styled from "styled-components"

// Utils
import { colors } from "utils/variables/"

// Components
import Container from "components/container/"
import Grid from "components/grid/"
import BlogCard from "components/blog-card/"
import LinkWithIcon from "components/link-with-icon/"

const StyledRelatedPosts = styled.section`
  padding-bottom: 80px;

  h3 {
    margin-bottom: 40px;
  }

  .post {
    padding: 12px;

    .card__content {
      padding: 0 4px;
    }
  }

  .related-posts__read-more {
    margin-top: 32px;
    text-align: right;
  }
`

const RelatedPosts = ({ data: relatedPosts }) => {
  if (relatedPosts) {
    return (
      <StyledRelatedPosts className="bg--cream">
        <Container>
          <h3>Read more Indi blog posts</h3>

          <Grid gutter="32px" columns="3">
            {relatedPosts &&
              relatedPosts.map((post, index) => (
                <div className="grid__item" key={index}>
                  <BlogCard className="post" {...post} />
                </div>
              ))}
          </Grid>

          <div className="related-posts__read-more">
            <LinkWithIcon
              to="/blog/"
              text="See all articles"
              iconColor={colors.indiblue}
            />
          </div>
        </Container>
      </StyledRelatedPosts>
    )
  }

  return null
}

export default RelatedPosts
